import { FC, useState } from 'react';
import { noop } from 'lodash';
import { useFormik } from 'formik';
import CreateAccountForm from 'components/Auth/forms/CreateAccountForm';
import { createAccount, getCurrentAccount } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ICreateAccountFormParams } from 'types';
import { createNotification, NotificationComponentTypes } from 'handlers/notificationsSlice';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import getMessage, { MessageType } from 'constants/Messages';
import BorrowerNamePerson from 'components/Auth/forms/BorrowerNamePerson';
import BorrowerNameCompany from 'components/Auth/forms/BorrowerNameCompany';
import BorrowerTypeToggleGroupWrapper from 'components/digifi-wrappers/BorrowerTypeToggleGroup';
import styles from './OnlineSignUp.module.scss';

const OnlineSignUp: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const availableBorrowerTypes = useAppSelector((state) => state.settings.borrowerTypes);

  const [selectedBorrowerType, setSelectedBorrowerType] = useState<BorrowerType>(availableBorrowerTypes[0]);

  const { values, errors, handleChange, resetForm,  setFieldValue, setFieldError } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: noop,
  });

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleFieldBlur = (variable: string, messageType: MessageType, value: string) => {
    const trimmedValue = value.trim();

    setFieldValue(variable, trimmedValue);
    setFieldError(variable as string, trimmedValue.length > 0 ? '' : getMessage(messageType));
  };

  const renderBorrowerTypeToggle = () => {
    if (availableBorrowerTypes?.length !== 2) {
      return;
    }

    return (
      <BorrowerTypeToggleGroupWrapper
        availableBorrowerTypes={availableBorrowerTypes}
        onChange={handleSetSelectedBorrowerType}
        selectedBorrowerType={selectedBorrowerType}
        buttonWrapperClassName={styles.toggleButton}
        labelTitle="Account Type"
        required
        optionTextOverwrite={{
          [BorrowerType.Person]: 'Personal Account',
          [BorrowerType.Company]: 'Business Account',
        }}
      />
    );
  };

  const renderBorrowerName = () => {
    return (
      <>
        {selectedBorrowerType === BorrowerType.Person && (
          <BorrowerNamePerson
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleFieldBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
          />
        )}
        {selectedBorrowerType === BorrowerType.Company && (
          <BorrowerNameCompany
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleFieldBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
          />
        )}
      </>
    );
  };

  const isNameFilled = selectedBorrowerType === BorrowerType.Person
    ? !!values.firstName.length && !!values.lastName
    : !!values.companyName;

  const handleSetSelectedBorrowerType = (borrowerType: BorrowerType) => {
    resetForm();
    setSelectedBorrowerType(borrowerType);
  };

  const handleSubmit = async (params: ICreateAccountFormParams) => {
    if (Object.values(errors).some((error) => !!error)) {
      return;
    }

    try {
      setIsLoading(true);
      await dispatchWithUnwrap(createAccount({ ...params, ...values, borrowerType: selectedBorrowerType }));
      await dispatchWithUnwrap(getCurrentAccount());
    } catch (error) {
      if (error.responseStatus === HttpStatusCode.Conflict) {
        createNotification({
          notification: { componentType: NotificationComponentTypes.CreateAccountComponent },
          type: 'error',
          dispatch,
        });
      } else {
        createNotification({
          notification: error.message,
          type: 'error',
          dispatch,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {renderBorrowerTypeToggle()}
      {renderBorrowerName()}
       <CreateAccountForm
         handleSubmit={handleSubmit}
         useLegalConsents={false}
         isLoading={isLoading}
         disabled={!isNameFilled}
         submitButtonClassName={styles.submitButton}
      />
    </div>
  );
};

export default OnlineSignUp;
