import React, { FC } from 'react';
import { SkeletonRectangle } from 'product_modules/components/Skeleton';
import { ICustomHtmlElementConfig } from 'api/digifi/portal-page-elements/CustomHtmlElement';

interface ICustomHtmlElementProps {
  config: ICustomHtmlElementConfig;
  containerClassName?: string;
  isLoading?: boolean;
}

const CustomHtmlElement: FC<ICustomHtmlElementProps> = ({
  config,
  containerClassName,
  isLoading,
}) => {
  const renderSkeleton = () => {
    return (
      <div className={containerClassName}>
        <SkeletonRectangle width="100%" height="104px" color="primary6" />
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className={containerClassName} dangerouslySetInnerHTML={{ __html: config.html }} />
  );
};

export default CustomHtmlElement;
